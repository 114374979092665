import { useAuthStore } from '~/stores/auth'
/*
Runs first in to protect Admin routes from non-admin users
*/
export default defineNuxtRouteMiddleware(async to => {
  const authStore = useAuthStore()
  const { user } = authStore

  try {
    const dashboardGroups = ['Admin', 'Partner']
    if (!dashboardGroups.includes(user.group)) {
      return navigateTo('/')
    }
    return // allow access to admin
  } catch {
    return to.path === '/login' ? undefined : navigateTo('/')
  }
})
